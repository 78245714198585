<template>
    <div>
        <div class="right-menu shipping-right">

            <subHeader pageName="EXPENSES"/>

            <tutorialsAction />

            <div class="db_top">
                <div class="db-container">

                    <moduleStats/>

                    <!-- search block starts here -->
                    <div class="formOptionsBox">
                        <div class="searchOptionsWrap">
                            <div class="searchBlock" id="expenses-search">
                                <form @submit.prevent="">
                                    <div class="searchIconWrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.491 17.49">
                                        <path id="Path_10" data-name="Path 10" d="M16,17.49h0L11,12.5v-.79l-.27-.28a6.512,6.512,0,1,1,.7-.7l.28.27h.79l4.99,5L16,17.489ZM6.5,2A4.5,4.5,0,1,0,11,6.5,4.505,4.505,0,0,0,6.5,2Z" fill="#848484"/>
                                        </svg>
                                    </div>
                                    <input
                                        type="text"
                                        name="search"
                                        id="search"
                                        ref="searchInput"
                                        placeholder="Search"
                                        v-model="searchedItem"
                                        @input="searchExpense()"
                                        :disabled="tourIsActive"
                                    />
                                </form>
                            </div>
                            <div class="filterShowTabsRow" v-if="searchedItem">
                                <div class="filterShowTabsListSearchedQuery">
                                    <p>Search results for "{{ searchedItem.length > 20 ? searchedItem.substr(0, 20) + "..." : searchedItem }}".</p>
                                </div>
                                <div class="filterShowTabsResetBtn">
                                    <button @click.prevent="clearSearch">Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- search block ends here -->

                    <!-- data table starts here -->
                    <div class="tableMainWrapBox" style="margin-top: 25px;position: relative;" id="preview-record">
                        <div class="tableScrollWrap" ref="expensesTable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <label class="ctmTableCheckContainer">
                                                    <input 
                                                        type="checkbox"
                                                        @click="toggleIsCheckedAll()"
                                                        :checked="checkedItems.length != 0 && checkedItems.length == expensesList.length"
                                                        :disabled="tourIsActive || this.previewForm.id != ''"
                                                    >
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Expense Amount">Amount</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Expense Type">Expense Type</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Expense Amount">Account Name</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Bank Name">Bank Name</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Action">Action</p>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="expensesLoading">
                                    <tr v-for="i in 5" :key="i">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-if="expensesList && expensesList.length == 0">
                                        <td colspan="7" style="text-align:center"><p class="mb-0">No Expenses Available</p></td>
                                    </tr>
                                    <tr v-for="(listedExpense, index) in expensesList" :key="index">

                                        <!-- checkbox block starts here -->
                                        <td @click="previewExpense(listedExpense,index,'input',$event)">
                                            <div class="ctmCheckWrap" data-open="true">
                                                <label class="ctmTableCheckContainer" data-open="false" @click.prevent=" listedExpense.id != -1 ? checkedItemHandle(listedExpense.id) : ''">
                                                    <input type="checkbox" v-model="checkedItems" :value="listedExpense.id" :disabled="tourIsActive || listedExpense.id == -1" data-open="false">
                                                    <span class="checkmark" data-open="false"></span>
                                                </label>
                                            </div>
                                        </td>
                                        <!-- checkbox block starts here -->

                                        <!-- expense ammount block starts here -->
                                        <td class="mainTableTitle" @click="previewExpense(listedExpense,index,'input',$event)">
                                            <div class="editFieldTitle" data-open="true" id="expense-amount-field-3">
                                                <input 
                                                    type="text" 
                                                    v-model="listedExpense.amount" 
                                                    @focus="expenseSelection(listedExpense)" 
                                                    @blur="handleOTGUpdate(listedExpense,index)" 
                                                    :disabled="tourIsActive"
                                                    data-open="false"
                                                >
                                            </div>
                                        </td>
                                        <!-- expense ammount block starts here -->

                                        <!-- expense type block starts here -->
                                        <td style="position: relative;" @click.prevent="previewExpense(listedExpense,index,'solid',$event)">
                                            <div class="selectCategoryBoxTable" data-open="true">
                                                <p data-open="true">{{ listedExpense.type ? listedExpense.type : '--' }}</p>
                                            </div>
                                        </td>
                                        <!-- expense type block ends here -->

                                        <!-- account name block starts here -->
                                        <td @click="previewExpense(listedExpense,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <p data-open="true">
                                                    {{ listedExpense.account ? listedExpense.account.account_name ? listedExpense.account.account_name.length > 15 ? 
                                                        listedExpense.account.account_name.substr(0,15) + "..." : listedExpense.account.account_name : '--' : '--'
                                                    }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- account name block ends here -->

                                        <!-- bank name block starts here -->
                                        <td @click="previewExpense(listedExpense,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <p data-open="true">
                                                    {{ listedExpense.account ? listedExpense.account.bank_name ? listedExpense.account.bank_name.length > 15 ? 
                                                        listedExpense.account.bank_name.substr(0,15) + "..." : listedExpense.account.bank_name : '--' : '--'
                                                    }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- bank name block ends here -->

                                        <!-- action block starts here -->
                                        <td @click.prevent="previewExpense(listedExpense,index,'input',$event)">
                                            <div class="ctmDataTableActionBtn" v-if="isDeletingItem(listedExpense.id) && deleteLoader" data-open="false">
                                                <loaderBtn />
                                            </div>
                                            <div class="ctmDataTableActionBtn" data-open="true" v-else>
                                                <button class="delFunction" @click.prevent="deleteExpenseHandle(listedExpense,index)" :disabled="tourIsActive" data-open="false">
                                                    <i aria-hidden="true" class="fa fa-trash-o" data-open="false"></i>
                                                </button>
                                            </div>
                                        </td>
                                        <!-- action block ends here -->

                                    </tr>

                                    <!-- load more block starts here -->
                                    <tr v-if="isPending">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- load more block ends here -->

                                </tbody>
                            </table>
                        </div>

                        <!-- add/update account form starts here -->
                        <div class="productSlideBox" ref="viewInputForm" :class="previewForm.id ? 'activeRow': ''" >
                            <div class="tableActionBtns" id="expense-details-preview">
                                <div class="productTableSideBar">
                                    <div class="row">
                                        <div class="col-md-8" id="expense-amount-field-2">
                                            <div class="viewOrderTitle">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write Your Expense Amount" 
                                                    v-model="previewForm.amount" 
                                                >
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="orderViewNav">
                                                <ul>
                                                    <el-tooltip class="box-item" effect="dark" content="Expand" placement="top-start">
                                                        <li class="sm-extand-hide">
                                                            <button @click.prevent="expand" :disabled="tourIsActive" id="expand-form">
                                                                <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
                                                                    <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                                                        <path d="m16.5 5.5v-4.978l-5.5.014"/>
                                                                        <path d="m16.5.522-6 5.907"/>
                                                                        <path d="m11 16.521 5.5.002-.013-5.5"/>
                                                                        <path d="m16.5 16.429-6-5.907"/>
                                                                        <path d="m.5 5.5v-5h5.5"/>
                                                                        <path d="m6.5 6.429-6-5.907"/>
                                                                        <path d="m6 16.516-5.5.007v-5.023"/>
                                                                        <path d="m6.5 10.5-6 6"/>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                    <el-tooltip class="box-item" effect="dark" content="Close" placement="top-start">
                                                        <li>
                                                            <button @click.prevent="closePreview" id="closeSide" :disabled="tourIsActive">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="29.446" height="33.409" viewBox="0 0 29.446 33.409">
                                                                    <g id="Group_20906" data-name="Group 20906" transform="translate(362.041 -2185.5)">
                                                                        <path id="Path_57522" data-name="Path 57522" d="M-361.041,2186.648v31.409" transform="translate(0 -0.148)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                        <g id="Group_20905" data-name="Group 20905" transform="translate(0 -0.5)">
                                                                            <path id="Path_57523" data-name="Path 57523" d="M-354.018,2202.5h20.265" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                            <path id="Path_57524" data-name="Path 57524" d="M-342.6,2193.747l8,9.15-8,8.85" transform="translate(1 -0.247)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sectionTabsLink">
                                    <ul onclick="window.myFunction(event)">
                                        <li v-if="!tourIsActive">
                                            <a class="active" id="category-detail-navbtn" href="#expenseDetails">Expense Details</a>
                                        </li>
                                    </ul>
                                    <div class="updateBtnBox" v-if="expenseIsSaving">
                                        <loaderBtn/>
                                    </div>
                                    <div class="updateBtnBox" id="save-expense-btn" v-else>
                                        <button 
                                            form="expense-information" 
                                            button="submit"
                                            @click="validate()" 
                                            :disabled="tourIsActive"
                                        >
                                            {{ previewForm.id == -1 ? 'Save' : 'Update' }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <ValidationObserver ref="expenseFormObserver">
                                <div class="productTableInfoBlock" id="expenseDetails">
                                    <div class="productTableInfoWhiteBox">
                                        <form id="expense-information" @submit.prevent="validate()">
                                            <div class="tableFromFieldBox" id="expense-amount">
                                                <label for="expense-amount-field">Amount<span>*</span></label>
                                                <ValidationProvider name="expense amount" :rules="{ required: true, regex: '^[0-9][0-9]*$' , max_value:99999999 }" v-slot="{ errors }">
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="text" 
                                                            name="expense-amount-field" 
                                                            id="expense-amount-field" 
                                                            v-model="previewForm.amount" 
                                                        >
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox" id="expense-date">
                                                <label for="expense-date-field">Date<span>*</span></label>
                                                <ValidationProvider name="expense date" rules="required" v-slot="{ errors }">
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="date" 
                                                            name="expense-date-field" 
                                                            id="expense-date-field" 
                                                            v-model="previewForm.date" 
                                                        >
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox" id="expense-type">
                                                <label for="expense-type-field">Type<span>*</span></label>
                                                <ValidationProvider name="expense type" rules="required" v-slot="{ errors }">
                                                    <div class="vueCtmSelectBlock">
                                                        <select
                                                            name="expense-type-field" 
                                                            id="expense-type-field" 
                                                            v-model="previewForm.type" 
                                                        >
                                                            <option 
                                                                :value="expenseType" 
                                                                v-for="(expenseType,index) in expenseTypes" 
                                                                :key="index"
                                                            >
                                                                {{ expenseType }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox" id="expense-customer">
                                                <label for="expense-customer-field">Customer<span>*</span></label>
                                                <ValidationProvider name="customer" rules="required" v-slot="{ errors }">
                                                    <div class="vueCtmSelectBlock">
                                                        <select
                                                            name="expense-customer-field" 
                                                            id="expense-customer-field" 
                                                            v-model="previewForm.customer" 
                                                        >
                                                            <option 
                                                                :value="customer" 
                                                                v-for="(customer,index) in customersList" 
                                                                :key="index"
                                                            >
                                                                {{ (customer.first_name + " " + customer.last_name).length > 100 ? 
                                                                    (customer.first_name + " " + customer.last_name).substr(0,100) : 
                                                                    customer.first_name + " " + customer.last_name
                                                                }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox" id="expense-vendor">
                                                <label for="expense-vendor-field">Vendor<span>*</span></label>
                                                <ValidationProvider name="vendor" rules="required" v-slot="{ errors }">
                                                    <div class="vueCtmSelectBlock">
                                                        <select
                                                            name="expense-vendor-field" 
                                                            id="expense-vendor-field" 
                                                            v-model="previewForm.vendor" 
                                                        >
                                                            <option 
                                                                :value="vendor" 
                                                                v-for="(vendor,index) in vendorsList" 
                                                                :key="index"
                                                            >
                                                                {{ vendor.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox" id="expense-account">
                                                <label for="expense-account-field">Account<span>*</span></label>
                                                <ValidationProvider name="account" rules="required" v-slot="{ errors }">
                                                    <div class="vueCtmSelectBlock">
                                                        <select
                                                            name="expense-account-field" 
                                                            id="expense-account-field" 
                                                            v-model="previewForm.account" 
                                                        >
                                                            <option 
                                                                :value="account" 
                                                                v-for="(account,index) in accountsList" 
                                                                :key="index"
                                                            >
                                                                {{ account.account_name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>

                                            <!-- new expense attachment for block starts here -->
                                            <div class="tableFromFieldBox" id="expense-attachment" v-if="this.previewForm.id == -1">
                                                <label for="addFormAttachment">Attach file</label><br />
                                                <div class="fileUploaderNameBox expense" v-if="previewForm.addAttachment">
                                                    <div class="fileUploader">
                                                        <div class="fileName">
                                                            <p>{{ previewForm.addAttachment ? previewForm.addAttachment.name.length > 15 ? 
                                                                    previewForm.addAttachment.name.substr(0,15) + "..." : previewForm.addAttachment.name : '' 
                                                                }}
                                                            </p>
                                                        </div>
                                                        <div class="fileAction">
                                                            <el-tooltip class="box-item" effect="dark" content="Remove" placement="top-start">
                                                                <svg width="18px" fill="#f00" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" @click.prevent="removeAttachment('add',$event)">
                                                                    <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z"/>
                                                                    <path d="M0 0h48v48H0z" fill="none"/>
                                                                </svg>
                                                            </el-tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="formField" v-if="!previewForm.addAttachment">
                                                    <input
                                                        type="file" 
                                                        id="addFormAttachment" 
                                                        ref="expenseFile" 
                                                        accept=".jpg,.jpeg,.png,.webp,.xlsx,.xls,.doc,.docx,.pdf"
                                                        name="uploadBill" 
                                                        @change.prevent="changeImageAddForm"
                                                    >
                                                </div>
                                            </div>
                                            <!-- new expense attachment for block ends here -->

                                            <!-- update expense attachment for block starts here -->
                                            <div class="tableFromFieldBox" id="expense-attachment" v-else>
                                                <label for="addFormAttachment">Attach file</label><br />
                                                <div class="fileUploaderNameBox expense" v-if="previewForm.attachment">
                                                    <div class="fileUploader">
                                                        <div class="fileName">
                                                            <p>{{ previewForm.attachment_src ? previewForm.attachment.name.length > 15 ? 
                                                                    previewForm.attachment.name.substr(0,15) + "..." : previewForm.attachment.name : previewForm.attachment ? 
                                                                    previewForm.attachment.length > 15 ? previewForm.attachment.substr(0,15) + "..." : previewForm.attachment
                                                                    : "--"
                                                                }}
                                                            </p>
                                                        </div>
                                                        <div class="fileAction" v-if="attachmentLoader">
                                                            <div class="item loading-6" >
                                                                <svg viewBox="25 25 50 50">
                                                                <circle cx="50" cy="50" r="20"></circle>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div class="fileAction" v-else>
                                                            <el-tooltip class="box-item" effect="dark" content="View" placement="top-start" v-if="!previewForm.attachment_src">
                                                                <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" @click.prevent="viewFileHandle()">
                                                                    <path d="M.2 10a11 11 0 0 1 19.6 0A11 11 0 0 1 .2 10zm9.8 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"/>
                                                                </svg>
                                                            </el-tooltip>
                                                            <el-tooltip class="box-item" effect="dark" content="Remove" placement="top-start">
                                                                <svg width="18px" fill="#f00" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" @click.prevent="removeAttachment()">
                                                                    <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z"/>
                                                                    <path d="M0 0h48v48H0z" fill="none"/>
                                                                </svg>
                                                            </el-tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="formField" v-if="!previewForm.attachment">
                                                    <input
                                                        type="file" 
                                                        id="addFormAttachment" 
                                                        ref="updateExpenseFile" 
                                                        accept=".jpg,.jpeg,.png,.webp,.xlsx,.xls,.doc,.docx,.pdf"
                                                        name="uploadBill" 
                                                        @change.prevent="changeImageUpdateForm"
                                                    >
                                                </div>
                                            </div>
                                            <!-- update attachment for block ends here -->

                                            <div class="tableFromFieldBox" id="expense-description">
                                                <ValidationProvider name="description" rules="max:500" v-slot="{ errors }">
                                                    <label for="expense-description-field">Description</label>
                                                    <div class="tableFromFieldItem">
                                                        <textarea 
                                                            name="expense-description-field" 
                                                            id="expense-description-field" 
                                                            placeholder="Description" 
                                                            v-model="previewForm.description"
                                                        >
                                                        </textarea>
                                                    </div>
                                                    <span class="text-danger" >{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                        <!-- add/update account form ends here -->

                    </div>
                    <!-- data table ends here -->

                    <!--bottom action bar starts here-->
                    <div class="row product-bottom-bar m-0" v-if="checkedItems.length > 1">
                        <div class="col-md-6 model-footer-main1">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 mr-5" @click.prevent="toggleIsCheckedAll()" v-if="checkedItems.length != expensesList.length">
                                    <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                    <p>SELECT ALL</p>
                                </a>
                                <a href="" class="model-footer-1" @click.prevent="clearSelection()">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                        <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                                    </svg>
                                    <p>CLEAR SELECTION</p>
                                </a>
                            </div>
                            <div class="model-footer-2">{{ checkedItems.length }}</div>
                        </div>
                        <div class="col-md-6 model-footer-main1 product-row-reverse">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 ml-4" @click.prevent="expensesBulkActionHandle('delete')">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                    <p>DELETE</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!--bottom action bar ends here-->

                </div>
            </div>

        </div>

        <!--vue tour-->
        <v-tour name="expensesTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

    </div>
</template>

<script>
import { MessageBox, Message } from "element-ui";
import _ from "lodash";
import { mapGetters } from 'vuex';
import subHeader from "@/components/subHeader.vue";
import moduleStats from "@/components/moduleStats.vue";
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";

export default {
    metaInfo() {
        return {
            title: 'Expenses/Purchases | Expenses | YeetCommerce',
        };
    },
    data(){
        return {
            base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,

            //search start
            searchedItem:'',
            //search end

            //expenses start
            expensesList:[],
            expensesLoading:false,
            expenseIsSaving:false,
            expenseSaved:false,
            previewForm:{
                id:'',
                amount:'',
                type:'',
                vendor_id:'',
                vendor:null,
                customer_id:'',
                customer:null,
                account_id:'',
                account:null,
                description:'',
                attachment: null,
                attachment_src: '',
                addAttachment:null,
                addAttachment_src:'',
                isTouched:false,
            },
            defaultPreviewForm:{
                id:'',
                amount:'',
                type:'',
                vendor_id:'',
                vendor:null,
                customer_id:'',
                customer:null,
                account_id:'',
                account:null,
                description:'',
                attachment: null,
                attachment_src: '',
                addAttachment:null,
                addAttachment_src:'',
                isTouched:false,
            },
            tempExpense:{
                id:'',
                amount:'',
                type:'',
                vendor_id:'',
                vendor:null,
                customer_id:'',
                customer:null,
                account_id:'',
                account:null,
                description:'',
                attachment: null,
                attachment_src: '',
                addAttachment:null,
                addAttachment_src:'',
                isTouched:false,
            },
            tempPreviewExpense:{
                id:'',
                amount:'',
                type:'',
                vendor_id:'',
                vendor:null,
                customer_id:'',
                customer:null,
                account_id:'',
                account:null,
                description:'',
                attachment: null,
                attachment_src: '',
                addAttachment:null,
                addAttachment_src:'',
                isTouched:false,
            },
            expenseTypes: [
                "IT & Internet",
                "Materials",
                "Rent",
                "Salary",
                "Travel",
                "Others",
            ],
            attachmentLoader:false,
            //expenses end

            //customers start
            customersList:[],
            //customers end

            //vendors start
            vendorsList:[],
            //vendors end

            //accounts start
            accountsList:[],
            //accounts end

            //image validations start
            imageValidations:{
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            },
            //image validations end

            //checked items start
            checkedItems:[],
            deletingItems:[],
            deleteLoader:false,
            timer: undefined,
            isMasterSearched:false,
            //checked items end

            //tour start
            tourOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip Tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks: {
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                //expenses steps
                {
                    target: '#expenses-search',
                    content: `<strong>Search Expense</strong><br>It enables you to search specific expenses.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#preview-record',
                    content: `<strong>Expenses Table</strong><br>You can preview and edit the added expenses by clicking on each individual record present in the table.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#add-new-record',
                    content: `Add an <strong>Expense</strong><br>By clicking this button, you can add a new expense and open a form to input and save the details.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#expense-details-preview',
                    content: `<strong>Expense Details Form</strong><br>This form can be used to add details about the expense and save them.`,
                    params: {
                        highlight: false,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#expense-amount',
                    content: `<strong>Expense Amount Field</strong><br>You can add the amount of expense using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#expense-date',
                    content: `<strong>Expense Date Field</strong><br>You can add the date of expense using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#expense-type',
                    content: `<strong>Expense Type Field</strong><br>You can select the type of expense using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#expense-customer',
                    content: `<strong>Customer Field</strong><br>You can select the customer related to expense using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#expense-vendor',
                    content: `<strong>Vendor Field</strong><br>You can select the vendor related to expense using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#expense-account',
                    content: `<strong>Account Field</strong><br>You can select the account related to expense using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#expense-attachment',
                    content: `<strong>Attachment Field</strong><br>You can add the attachment related to expense using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#expense-description',
                    content: `<strong>Description Field</strong><br>You can add the description related to expense using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#expense-amount-field-3',
                    content: `<strong>Expense Amount Field</strong><br>You can also add the amount of expense using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#expense-amount-field-2',
                    content: `<strong>Expense Amount Field</strong><br>You can also add the amount of expense using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#expand-form',
                    content: `<strong>Expand Form</strong><br>Form can be expanded or collapsed by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#closeSide',
                    content: `<strong>Close Form</strong><br>Form can be closed using this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#save-expense-btn',
                    content: `<strong>Save Expense Details</strong><br>Added expense details can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
            ],
            tourIsActive:false,
            //tour end
        }
    },
    components:{
        subHeader,
        tutorialsAction,
        moduleStats,
        loaderBtn
    },
    computed:{
        ...mapGetters({
            settings:'settings_module/settings',
            storeExpenses:'expenses_module/expenses',
            expensesCurrentPage: 'expenses_module/currentPage',
            expensesLastPage: 'expenses_module/lastPage',
            expensesSearchKeyword:'expenses_module/keyword',
            isPending:'expenses_module/isPending',
            barGraphYear:'bar_graph_module/storeYear',
        })
    },
    watch:{
        'storeExpenses':{
            handler:function(value){

                this.expensesLoading = false;

                if(this.previewForm.id == -1){
        
                    this.expensesList = _.cloneDeep(value);
                    
                    this.expensesList.unshift(this.previewForm);

                }else if(this.tempPreviewExpense.id != ''){

                    this.expensesList = _.cloneDeep(value);

                    this.expensesList[this.previewForm.index] = this.previewForm;

                }else{

                    this.expensesList = _.cloneDeep(value);

                }

                if(this.isMasterSearched){

                    this.previewSearchedExpense(this.expensesList[0],0);

                    this.isMasterSearched = false;

                }

            },deep:true
        },
        'previewForm':{
            handler:function(value){

                if((value.id == -1) && (value.vendor || value.customer || value.account || value.description 
                || value.attachment || value.addAttachment )){

                    value.isTouched = true;

                }else{

                    value.isTouched = false;

                }

            },deep:true
        },
    },
    methods:{
        //infinite scroll start
        handleScrollEvent(event){

            let element = this.$refs.expensesTable;

            let maxScrollTop = element.scrollHeight - element.clientHeight;

            if(element.scrollTop >= maxScrollTop - 100){

                if(this.isPending == false && this.expensesCurrentPage != this.expensesLastPage){

                    this.$store.dispatch('expenses_module/fetchStoreExpensesRequest',{size: 12, page: this.expensesCurrentPage + 1, loadData: 'more'});
                
                }

            }

        },
        //infinite scroll end

        //OTG update start
        expenseSelection(listedExpense){

            this.tempExpense = _.cloneDeep(listedExpense);

        },
        async handleOTGUpdate(listedExpense,index){

            if(this.previewForm.id == ''){

                if(listedExpense.id != -1){

                    let isChanged = false;

                    if(this.tempExpense.amount != listedExpense.amount){

                        isChanged = true;

                    }

                    if(isChanged){

                        let isRequired = false;

                        if(listedExpense.amount.length == 0){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The expense amount field is required.",
                            });

                            isRequired = true;

                        }

                        if(listedExpense.amount > 99999999){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The expense amount may not be greater than 99999999.",
                            });

                            isRequired = true;

                        }

                        let regex = new RegExp('^[0-9][0-9]*$');

                        let isValidExpenseAmount = regex.test(listedExpense.amount);

                        if(listedExpense.amount && !isValidExpenseAmount){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The amount may only contain numbers.",
                            });

                            isRequired = true;

                        }

                        if(!listedExpense.account && !listedExpense.account){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "Account and vendor needs to be associated with the expense.",
                            });

                            isRequired = true;

                        }else if(!listedExpense.account && listedExpense.vendor){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "Account needs to be associated with the expense.",
                            });

                            isRequired = true;
                            
                        }else if(listedExpense.account && !listedExpense.vendor){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "Vendor needs to be associated with the expense.",
                            });

                            isRequired = true;

                        }

                        if(isRequired){

                            this.expensesList[index].amount = this.tempExpense.amount;

                            return

                        }

                        MessageBox.confirm(
                            'You have unsaved changes, save and proceed?',
                            'Confirm',
                            {
                                distinguishCancelAndClose: true,
                                confirmButtonText: 'Save',
                                cancelButtonText: 'Discard Changes',
                            }
                        ).then(async () => {

                            let formData = new FormData();

                            formData.append("id", listedExpense.id);
                            formData.append("date", listedExpense.date);
                            formData.append("vendor_id", listedExpense.vendor.id);
                            formData.append("customer_id", listedExpense.customer.id);
                            formData.append("account_id", listedExpense.account.id);
                            formData.append("type", listedExpense.type);
                            formData.append("amount", listedExpense.amount);

                            if(listedExpense.description){

                                formData.append('description',listedExpense.description);

                            }

                            try{
                                let res = await this.$axios.post('/expense/update',formData);
                                if(res.data.status_code == "1062"){

                                    this.$notify({
                                        type: "success",
                                        title: "Success",
                                        message: res.data.message,
                                    });

                                    this.$store.commit('expenses_module/update_expenses_list',res.data.expense);

                                    this.$store.dispatch('bar_graph_module/fetchBarGraphRequest',this.barGraphYear)

                                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                                    this.$store.commit('gallery_module/CLEAR_MODULE');

                                }
                            }catch(error){

                                this.expensesList[index].amount = this.tempExpense.amount;

                                if(error.response){

                                    if(error.response.data.error.id){

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.error.id[0],
                                        });

                                    }else if(error.response.data.error.includes('storage limit has been reached')){

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            dangerouslyUseHTMLString: true,
                                            message: error.response.data.error,
                                        });

                                    }else if(error.response.data.error.includes("doesn't support")){

                                        let subscriptionPath = window.location.origin + "/subscription";

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            dangerouslyUseHTMLString: true,
                                            message: error.response.data.error 
                                            + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                                        });

                                    }else{

                                        this.$message({
                                            type: "error",
                                            showClose: true,
                                            message: error.response.data.message ,
                                        });

                                    }

                                }else{

                                    this.$message({
                                        type: "error",
                                        showClose: true,
                                        message: error.message,
                                    });

                                }

                                this.tempExpense = null;

                            }

                        }).catch((error) => {

                            this.expensesList[index].amount = this.tempExpense.amount;

                            this.$notify({
                                type: "info",
                                title: "Changes Discarded",
                                message: "Changes discarded successfully.",
                            });

                            this.tempExpense = null;

                        });

                    }

                }

            }else{

                if(listedExpense.id != -1){

                    if(listedExpense.id != this.previewForm.id){
                        
                        let isChanged = false;

                        if(this.tempExpense.amount != listedExpense.amount){

                            isChanged = true;

                        }

                        if(isChanged){

                            let isRequired = false;

                            if(listedExpense.amount.length == 0){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The expense amount field is required.",
                                });

                                isRequired = true;

                            }

                            if(listedExpense.amount > 99999999){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The expense amount may not be greater than 99999999.",
                                });

                                isRequired = true;

                            }

                            let regex = new RegExp('^[0-9][0-9]*$');

                            let isValidExpenseAmount = regex.test(listedExpense.amount);

                            if(listedExpense.amount && !isValidExpenseAmount){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The amount may only contain numbers.",
                                });

                                isRequired = true;

                            }

                            if(isRequired){

                                this.expensesList[index].amount = this.tempExpense.amount;

                                return

                            }

                            if(!listedExpense.account && !listedExpense.account){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "Account and vendor needs to be associated with the expense.",
                                });

                                isRequired = true;

                            }else if(!listedExpense.account && listedExpense.vendor){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "Account needs to be associated with the expense.",
                                });

                                isRequired = true;

                            }else if(listedExpense.account && !listedExpense.vendor){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "Vendor needs to be associated with the expense.",
                                });

                                isRequired = true;

                            }

                            MessageBox.confirm(
                                'You have unsaved changes, save and proceed?',
                                'Confirm',
                                {
                                    distinguishCancelAndClose: true,
                                    confirmButtonText: 'Save',
                                    cancelButtonText: 'Discard Changes',
                                }
                            ).then(async () => {

                                let formData = new FormData();

                                formData.append("id", listedExpense.id);
                                formData.append("date", listedExpense.date);
                                formData.append("vendor_id", listedExpense.vendor.id);
                                formData.append("customer_id", listedExpense.customer.id);
                                formData.append("account_id", listedExpense.account.id);
                                formData.append("type", listedExpense.type);
                                formData.append("amount", listedExpense.amount);

                                if(listedExpense.description){

                                    formData.append('description',listedExpense.description);

                                }

                                try{
                                    let res = await this.$axios.post('/expense/update',formData);
                                    if(res.data.status_code == "1062"){

                                        this.$notify({
                                            type: "success",
                                            title: "Success",
                                            message: res.data.message,
                                        });

                                        this.$store.commit('expenses_module/update_expenses_list',res.data.expense);

                                        this.$store.dispatch('bar_graph_module/fetchBarGraphRequest',this.barGraphYear)

                                        this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                                        this.$store.commit('gallery_module/CLEAR_MODULE');

                                    }
                                }catch(error){

                                    this.expensesList[index].amount = this.tempExpense.amount;

                                    if(error.response){

                                        if(error.response.data.error.id){

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                message: error.response.data.error.id[0],
                                            });

                                        }else if(error.response.data.error.includes('storage limit has been reached')){

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                dangerouslyUseHTMLString: true,
                                                message: error.response.data.error,
                                            });

                                        }else if(error.response.data.error.includes("doesn't support")){

                                            let subscriptionPath = window.location.origin + "/subscription";

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                dangerouslyUseHTMLString: true,
                                                message: error.response.data.error 
                                                + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                                            });

                                        }else{

                                            this.$message({
                                                type: "error",
                                                showClose: true,
                                                message: error.response.data.message ,
                                            });

                                        }

                                    }else{

                                        this.$message({
                                            type: "error",
                                            showClose: true,
                                            message: error.message,
                                        });

                                    }

                                    this.tempExpense = null;

                                }

                            }).catch((error) => {

                                this.expensesList[index].amount = this.tempExpense.amount;

                                this.$notify({
                                    type: "info",
                                    title: "Changes Discarded",
                                    message: "Changes discarded successfully.",
                                });

                                this.tempExpense = null;

                            });

                        }
                    
                    }

                }

            }

        },
        //OTG update end

        //preview form open/close & reset start
        previewExpense(listedExpense,i,field,e){

            if(field == 'input'){

                if((e.target.tagName == "TD" || e.target.dataset.open == "true") && !this.tourIsActive){
                    
                    if(listedExpense.id != -1){

                        if(this.previewForm.id == -1){

                            MessageBox.confirm(
                                "Are you sure? unsaved changes will be discarded", 
                                "Warning", 
                                {
                                    type: "warning",
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                }
                            ).then(()=>{

                                let index = this.expensesList.findIndex((expense) => expense.id == -1);
                                
                                if(index != -1){
                    
                                    this.expensesList.splice(index, 1);
                    
                                }

                                if(!this.expenseSaved){

                                    let tempIndex = this.expensesList.findIndex(x=>x.id == this.tempPreviewExpense.id);

                                    this.expensesList[tempIndex] = this.tempPreviewExpense;

                                }

                                let expense = listedExpense;

                                this.previewForm = expense;
                                this.previewForm.index = i;
                                this.previewForm.attachment_src = null;
                                this.previewForm.attachment = expense.attachment;

                                this.checkedItems = [];

                                this.tempPreviewExpense = _.cloneDeep(expense);

                            }).catch(() => {})

                        }else{

                            let index = this.expensesList.findIndex((expense) => expense.id == -1);
                                
                            if(index != -1){
                
                                this.expensesList.splice(index, 1);
                
                            }

                            if(!this.expenseSaved && (this.tempPreviewExpense.id != '' && this.tempPreviewExpense.id != listedExpense.id)){
                                
                                let tempIndex = this.expensesList.findIndex(x=>x.id == this.tempPreviewExpense.id);
                
                                this.expensesList[tempIndex] = this.tempPreviewExpense;
                
                            }

                            if(this.tempPreviewExpense.id != '' && this.tempPreviewExpense.id == listedExpense.id){

                                let tempIndex = this.expensesList.findIndex(x=>x.id == this.tempPreviewExpense.id);

                                this.expensesList[tempIndex] = _.cloneDeep(this.storeExpenses[tempIndex]);

                                let expense = _.cloneDeep(this.expensesList[tempIndex]);

                                this.previewForm = this.expensesList[tempIndex];
                                this.previewForm.index = i;
                                this.previewForm.attachment_src = null;
                                this.previewForm.attachment = expense.attachment;

                                this.checkedItems = [];

                                this.tempPreviewExpense = _.cloneDeep(expense);

                            }else{

                                let expense = listedExpense;

                                this.previewForm = expense;
                                this.previewForm.index = i;
                                this.previewForm.attachment_src = null;
                                this.previewForm.attachment = expense.attachment;

                                this.checkedItems = [];

                                this.tempPreviewExpense = _.cloneDeep(expense);

                            }

                        }

                    }
                
                }

            }else{

                if(!this.tourIsActive){
                
                    if(listedExpense.id != -1){

                        if(this.previewForm.id == -1){

                            MessageBox.confirm(
                                "Are you sure? unsaved changes will be discarded", 
                                "Warning", 
                                {
                                    type: "warning",
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                }
                            ).then(()=>{

                                let index = this.expensesList.findIndex((expense) => expense.id == -1);
                                
                                if(index != -1){
                    
                                    this.expensesList.splice(index, 1);
                    
                                }

                                if(!this.expenseSaved){

                                    let tempIndex = this.expensesList.findIndex(x=>x.id == this.tempPreviewExpense.id);

                                    this.expensesList[tempIndex] = this.tempPreviewExpense;

                                }

                                let expense = listedExpense;

                                this.previewForm = expense;
                                this.previewForm.index = i;
                                this.previewForm.attachment_src = null;
                                this.previewForm.attachment = expense.attachment;

                                this.checkedItems = [];

                                this.tempPreviewExpense = _.cloneDeep(expense);

                            }).catch(() => {})

                        }else{

                            let index = this.expensesList.findIndex((expense) => expense.id == -1);
                                
                            if(index != -1){
                
                                this.expensesList.splice(index, 1);
                
                            }

                            if(!this.expenseSaved && (this.tempPreviewExpense.id != '' && this.tempPreviewExpense.id != listedExpense.id)){
                                
                                let tempIndex = this.expensesList.findIndex(x=>x.id == this.tempPreviewExpense.id);
                
                                this.expensesList[tempIndex] = this.tempPreviewExpense;
                
                            }

                            if(this.tempPreviewExpense.id != '' && this.tempPreviewExpense.id == listedExpense.id){

                                let tempIndex = this.expensesList.findIndex(x=>x.id == this.tempPreviewExpense.id);

                                this.expensesList[tempIndex] = _.cloneDeep(this.storeExpenses[tempIndex]);

                                let expense = _.cloneDeep(this.expensesList[tempIndex]);

                                this.previewForm = this.expensesList[tempIndex];
                                this.previewForm.index = i;
                                this.previewForm.attachment_src = null;
                                this.previewForm.attachment = expense.attachment;

                                this.checkedItems = [];

                                this.tempPreviewExpense = _.cloneDeep(expense);

                            }else{

                                let expense = listedExpense;

                                this.previewForm = expense;
                                this.previewForm.index = i;
                                this.previewForm.attachment_src = null;
                                this.previewForm.attachment = expense.attachment;

                                this.checkedItems = [];

                                this.tempPreviewExpense = _.cloneDeep(expense);

                            }

                        }

                    }
                
                }

            }

        },
        previewSearchedExpense(listedExpense,i){

            let index = this.expensesList.findIndex((expense) => expense.id == -1);
                                
            if(index != -1){

                this.expensesList.splice(index, 1);

            }

            if(!this.expenseSaved){

                let tempIndex = this.expensesList.findIndex(x=>x.id == this.tempPreviewExpense.id);

                this.expensesList[tempIndex] = this.tempPreviewExpense;

            }

            let expense = listedExpense;

            this.previewForm = expense;
            this.previewForm.index = i;
            this.previewForm.attachment_src = null;
            this.previewForm.attachment = expense.attachment;

            this.checkedItems = [];

            this.tempPreviewExpense = _.cloneDeep(expense);

        },
        closePreview(){

            if(this.previewForm.isTouched){

                MessageBox.confirm(
                    "Do you want to close? Entered information will be discarded.", 
                    "Warning", 
                    {
                        type: "warning",
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                    }
                ).then(async () => {

                    let index = this.expensesList.findIndex((expense) => expense.id == -1);
                                
                    if(index != -1){
            
                        this.expensesList.splice(index, 1);
            
                    }
            
                    if(!this.expenseSaved){
            
                        let tempIndex = this.expensesList.findIndex(x=>x.id == this.tempPreviewExpense.id);
            
                        this.expensesList[tempIndex] = this.tempPreviewExpense;
            
                    }
    
                    this.previewForm = _.cloneDeep(this.defaultPreviewForm);
                    
                    this.expenseSaved = false;
    
                    this.tempExpense = _.cloneDeep(this.defaultPreviewForm);
    
                    this.tempPreviewExpense = _.cloneDeep(this.defaultPreviewForm);

                }).catch(() => {})

            }else{

                let index = this.expensesList.findIndex((expense) => expense.id == -1);
                                
                if(index != -1){
        
                    this.expensesList.splice(index, 1);
        
                }
        
                if(!this.expenseSaved){
        
                    let tempIndex = this.expensesList.findIndex(x=>x.id == this.tempPreviewExpense.id);
        
                    this.expensesList[tempIndex] = this.tempPreviewExpense;
        
                }

                this.previewForm = _.cloneDeep(this.defaultPreviewForm);
                
                this.expenseSaved = false;

                this.tempExpense = _.cloneDeep(this.defaultPreviewForm);

                this.tempPreviewExpense = _.cloneDeep(this.defaultPreviewForm);

            }

        },
        resetPreview(){

            let index = this.expensesList.findIndex((expense) => expense.id == -1);
                                
            if(index != -1){
    
                this.expensesList.splice(index, 1);
    
            }
    
            this.previewForm = _.cloneDeep(this.defaultPreviewForm);
            
            this.expenseSaved = false;

            this.tempExpense = _.cloneDeep(this.defaultPreviewForm);

            this.tempPreviewExpense = _.cloneDeep(this.defaultPreviewForm);

        },
        createExpense(){

            this.closePreview();

            if(this.expensesLoading){

                this.$notify({
                    type: "warning",
                    title: "Loading Data",
                    message: "Please Wait",
                });

                return

            }

            let index = this.expensesList.findIndex(x=>x.id == -1);

            if(index != -1){

                // this.$notify({
                //   type: "error",
                //   title: "Can't Add New Expense",
                //   message: "you have an unsaved expense in the list",
                // });

                return
            }

            this.previewForm = _.cloneDeep(this.defaultPreviewForm);

            this.previewForm.id = -1;

            let tempExpense = this.previewForm;

            this.expensesList.unshift(tempExpense);

            this.checkedItems = [];

            this.$refs.expenseFormObserver.reset();

        },
        //preview form open/close & reset end

        //add/update & delete expense start
        validate(){

            this.$refs.expenseFormObserver.validate().then((success) => {

                if(!success){

                    const errors = Object.entries(this.$refs.expenseFormObserver.errors)
                    .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                    this.$refs.expenseFormObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

                }else{

                    if(this.previewForm.id == -1){

                        this.addExpenseHandle();

                    }else{

                        this.updateExpenseHandle();

                    }

                }

            });

        },
        async addExpenseHandle(){

            let formData = new FormData();

            formData.append("vendor_id", this.previewForm.vendor.id);
            formData.append("customer_id", this.previewForm.customer.id);
            formData.append("type", this.previewForm.type);
            formData.append("amount", this.previewForm.amount);
            formData.append("account_id", this.previewForm.account.id);
            formData.append("date", this.previewForm.date);

            if(this.previewForm.description){

                formData.append('description',this.previewForm.description);

            }

            if(this.previewForm.addAttachment){

                formData.append('attachment',this.previewForm.addAttachment);

            }

            this.expenseIsSaving = true;
            try{
                let res = await this.$axios.post('/expense/add',formData);
                if(res.data.status_code == "1061"){

                    this.$notify({
                        type: "success",
                        title: "Success",
                        message: res.data.message,
                    });

                    this.$store.commit('expenses_module/add_expenses_list',res.data.expense);

                    this.$store.dispatch('bar_graph_module/fetchBarGraphRequest',this.barGraphYear);

                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                    this.$store.commit('gallery_module/CLEAR_MODULE');

                    this.expenseSaved = true;

                    this.resetPreview();

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.includes('storage limit has been reached')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error,
                        });

                    }else if(error.response.data.error.includes("doesn't support")){

                        let subscriptionPath = window.location.origin + "/subscription";

                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error 
                            + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                        });

                    }else{

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: "error",
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.expenseIsSaving = false;

            }

        },
        async updateExpenseHandle(){

            let formData = new FormData();

            formData.append('id',this.previewForm.id);
            formData.append('vendor_id',this.previewForm.vendor.id);
            formData.append('customer_id',this.previewForm.customer.id);
            formData.append('type',this.previewForm.type);
            formData.append('amount',this.previewForm.amount);
            formData.append('account_id',this.previewForm.account.id);
            formData.append('date',this.previewForm.date);
            
            if(this.previewForm.description){

                formData.append('description',this.previewForm.description);

            }

            if(this.previewForm.attachment_src){

                formData.append('attachment',this.previewForm.attachment);

            }

            this.expenseIsSaving = true;
            try{
                let res = await this.$axios.post('/expense/update',formData);
                if(res.data.status_code == "1062"){

                    this.$notify({
                        type: "success",
                        title: "Success",
                        message: res.data.message,
                    });

                    this.expensesList[this.previewForm.index] = res.data.expense;

                    this.$store.commit('expenses_module/update_expenses_list',res.data.expense);

                    this.$store.dispatch('bar_graph_module/fetchBarGraphRequest',this.barGraphYear);

                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                    this.$store.commit('gallery_module/CLEAR_MODULE');

                    this.expenseSaved = true;

                    this.closePreview();

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.id){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.id[0],
                        });

                    }else if(error.response.data.error.includes('storage limit has been reached')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error,
                        });

                    }else if(error.response.data.error.includes("doesn't support")){

                        let subscriptionPath = window.location.origin + "/subscription";

                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error 
                            + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                        });

                    }else{

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.response.data.message ,
                        });

                    }
                    
                }else{

                    this.$message({
                        type: "error",
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.expenseIsSaving = false;

            }

        },
        deleteExpenseHandle(listedExpense,index){

            if(!this.tourIsActive){
            
                MessageBox.confirm(
                    "Are you sure you want to delete the expense?", 
                    "Warning", 
                    {
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                        type: "warning",
                    }
                ).then(async () => {

                    this.deletingItems.push(listedExpense.id);
                    this.deleteLoader = true;

                    try{
                        let res = await this.$axios.delete(`expense/remove/${listedExpense.id}`);
                        if(res.data.status_code == "1063"){

                            this.$notify({
                                type: "success",
                                title: "Success",
                                message: res.data.message,
                            });

                            this.$store.commit('expenses_module/delete_expenses_list',index);

                            this.$store.dispatch('bar_graph_module/fetchBarGraphRequest',this.barGraphYear);

                            this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                            this.$store.commit('gallery_module/CLEAR_MODULE');

                        }
                    }catch(error){

                        if(error.response){

                            if(error.response.data.error.includes("doesn't support")){

                                let subscriptionPath = window.location.origin + "/subscription";

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    dangerouslyUseHTMLString: true,
                                    message: error.response.data.error 
                                    + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                                });

                            }else{

                                this.$message({
                                    type: "error",
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }

                        }else{

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.message,
                            });

                        }

                    }finally{

                        this.deletingItems = [];
                        this.deleteLoader = false;

                    }

                }).catch(() => {})
            
            }

        },
        //add/update & delete expense end

        //bulk action start
        expensesBulkActionHandle(action){

            this.resetPreview();

            MessageBox.confirm(
                'Do you really want to make the changes?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then(async ()=>{

                if(action == 'delete'){
                
                    let formData = new FormData();

                    formData.append("expenses", JSON.stringify(this.checkedItems));
                    formData.append("action", action);

                    this.deletingItems = _.cloneDeep(this.checkedItems)
                    this.deleteLoader = true;

                    try{
                        let res = await this.$axios.post('/expense/bulk-action',formData);
                        if(res.data.status_code == "1063"){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: 'The expenses have been successfully removed.',
                            });

                            let payload = {
                                action: 'delete',
                                listIds: this.checkedItems
                            }

                            this.$store.commit('expenses_module/expenses_bulk_action',payload);

                            this.checkedItems = [];
                            this.deletingItems = [];
                            this.deleteLoader = false;

                        }
                    }catch(error){

                        this.deletingItems = [];
                        this.deleteLoader = false;

                        if(error.response.data.error.includes("doesn't support")){

                            let subscriptionPath = window.location.origin + "/subscription";

                            this.$message({
                                type: 'error',
                                showClose: true,
                                dangerouslyUseHTMLString: true,
                                message: error.response.data.error 
                                + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                            });

                        }else if(error.response.data.message){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: "One of the expenses may have been deleted or isn't available.",
                            });

                        }

                    }
                
                }

            }).catch(() => {})

        },
        //bulk action end

        //search expense start
        searchExpense(){

            this.closePreview();

            this.expensesLoading = true;

            clearTimeout(this.timer)

            this.timer = setTimeout(() => {

                if(this.searchedItem){

                    let payload = {
                        keyword: this.searchedItem,
                    }
                    
                    this.$store.commit('expenses_module/update_searched_keyword',payload)
                    this.$store.dispatch('expenses_module/fetchStoreExpensesRequest',{size: 12, page: 1, loadData: 'new'});

                }else{

                    this.$store.commit('expenses_module/remove_searched_keyword')
                    this.$store.dispatch('expenses_module/fetchStoreExpensesRequest',{size: 12, page: 1, loadData: 'new'});
                
                }

            }, 500)

        },
        clearSearch(){

            this.searchedItem = ''

            this.searchExpense();

        },
        //search expense end

        //other methods start
        async changeImageAddForm(e){
      
            this.imageValidates = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }
            
            if(e.target.files.length > 0){

                const file = e.target.files[0];
                if(
                    file.type == 'image/jpeg' || 
                    file.type == 'image/jpg' || 
                    file.type == 'image/webp' || 
                    file.type == 'image/png' ||
                    file.type == 'application/pdf' ||
                    file.type == 'application/msword' ||
                    file.type == 'application/vnd.ms-excel' ||
                    file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                    file.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                    file.type == 'text/csv'
                ){

                    this.previewForm.addAttachment = file;
                    this.previewForm.addAttachment_src = URL.createObjectURL(file);

                    this.imageValidations.imgIsRequired = false;

                    this.$refs.expenseFile.value = ''

                }else if(file.type.includes('image/')){

                    let uploadedFileType = file.type.slice(6)
                    
                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
                    });

                    this.$refs.expenseFile.value = ''

                }else{

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file must be an image.",
                    }); 

                    this.$refs.expenseFile.value = ''

                }

            }

        },
        async changeImageUpdateForm(e){

            this.imageValidates = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }
            
            if(e.target.files.length > 0){

                const file = e.target.files[0];

                if(
                    file.type == 'image/jpeg' || 
                    file.type == 'image/jpg' || 
                    file.type == 'image/webp' || 
                    file.type == 'image/png' ||
                    file.type == 'application/pdf' ||
                    file.type == 'application/msword' ||
                    file.type == 'application/vnd.ms-excel' ||
                    file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                    file.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                    file.type == 'text/csv'
                ){

                    this.previewForm.attachment = file;
                    this.previewForm.attachment_src = URL.createObjectURL(file);

                    this.imageValidations.imgIsRequired = false;

                    this.$refs.updateExpenseFile.value = ''

                }else if(file.type.includes('image/')){

                    let uploadedFileType = file.type.slice(6)
                    
                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
                    });

                    this.$refs.updateExpenseFile.value = ''

                }else{

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file must be of a valid format.",
                    }); 

                    this.$refs.updateExpenseFile.value = ''

                }

            }

        },
        viewFileHandle(){

            if(!this.attachmentLoader){

                let fileURI = this.base_image_url + this.settings.store_id + "/expenses/" + this.previewForm.attachment

                const link = document.createElement('a');

                link.href = fileURI;

                // Set the download attribute to force download
                link.setAttribute('download', '');
                link.setAttribute('target', '_blank');

                // // Append the anchor element to the body
                // document.body.appendChild(link);

                // // Trigger a click event on the anchor element
                link.click();

                // // Remove the anchor element from the body
                // document.body.removeChild(link);

            }

        },
        removeAttachment(){

            if(this.previewForm.id == -1){

                this.previewForm.addAttachment = null;
                this.previewForm.addAttachment_src = null;

            }else{

                if(this.previewForm.attachment_src){

                    this.previewForm.attachment = null;
                    this.previewForm.attachment_src = null;

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: "The attachment has been successfully removed.",
                    });

                }else if(this.tempPreviewExpense.attachment){

                    MessageBox.confirm(
                        "Are you sure you want to delete the attachment?", 
                        "Warning", 
                        {
                            confirmButtonText: "Yes",
                            cancelButtonText: "Cancel",
                            type: "warning",
                        }
                    ).then(async () => {

                        let formData = new FormData();

                        formData.append('expense_id',this.previewForm.id);

                        this.attachmentLoader = true;
                        try{
                            let res = await this.$axios.post('/expense/attachment/remove',formData);
                            if(res.data.status_code == "1064"){

                                this.$notify({
                                    type: 'success',
                                    title: 'Success',
                                    message: res.data.message,
                                });

                                this.tempPreviewExpense.attachment = null;

                                this.previewForm.attachment = null;

                                this.$store.commit('expenses_module/update_expenses_list',res.data.expense);

                                this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                            }
                        }catch(error){

                            if(error.response){

                                if(error.response.data.error.includes("doesn't support")){

                                    let subscriptionPath = window.location.origin + "/subscription";

                                    this.$message({
                                        type: 'error',
                                        showClose: true,
                                        dangerouslyUseHTMLString: true,
                                        message: error.response.data.error 
                                        + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                                    });

                                }else{

                                    this.$message({
                                        type: 'error',
                                        showClose: true,
                                        message: error.response.data.message,
                                    });

                                }

                            }else{

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.message,
                                });

                            }

                        }finally{

                            this.attachmentLoader = false;

                        }

                    }).catch(() => {})

                }

            }

        },
        isDeletingItem(id){

            let find = this.deletingItems.find(x=>x == id);

            if(find){

                return true;

            }else{

                return false;

            }

        },
        checkedItemHandle(expenseId){

            if(!this.tourIsActive && expenseId != -1 && !this.previewForm.id){

                let index = this.checkedItems.findIndex(item => item == expenseId);

                if(index != -1){

                    this.checkedItems.splice(index,1)

                }else{

                    this.checkedItems.push(expenseId)

                }

            }

        },
        toggleIsCheckedAll(){

            if(!this.tourIsActive && !this.previewForm.id){

                if((this.checkedItems.length == this.expensesList.length) ||
                (this.checkedItems.length == this.expensesList.length)){

                    this.checkedItems = []

                }else if(this.checkedItems.length < this.expensesList.length){

                    this.checkedItems = []
                    
                    this.expensesList.forEach(expense => {

                        if(expense.id != -1){

                            this.checkedItems.push(expense.id)

                        }

                    });

                }

            }

        },
        clearSelection(){

            this.checkedItems = [];

        },
        expand(){

            this.$refs.viewInputForm.classList.toggle('fullWidthSlide');

        },
        keyEvent(e){

            if(e.key == 'Escape'){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.$refs.viewInputForm.classList.remove('fullWidthSlide');

                }else if(this.$refs.viewInputForm.classList.contains('activeRow')){

                    this.closePreview();

                }

            }

        },
        //other methods end

        //tour methods start
        startExpensesTour(){

            this.$tours.expensesTour.start();

            this.tourIsActive = true;

            if(this.previewForm.id != ''){

                this.closePreview();

            }

        },
        handleNextStep(currentStep){

            if(currentStep + 1 == 7){

                document.querySelector(".tableActionBtns").style.position = "static"

                document.querySelector(".productSlideBox").style.overflowY  = "hidden"

                this.createExpense();

            }

            if(currentStep >= 16){

                document.querySelector(".tableActionBtns").style.position = "sticky"

            }

            if(currentStep + 1 == 19){

                if(!this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand()

                }

            }

            if(currentStep + 1 == 20){

                this.expand()

            }

        },
        handlePreviousStep(currentStep){

            if(currentStep == 7){

                this.resetPreview();

                document.querySelector(".tableActionBtns").style.position = "sticky"

                document.querySelector(".productSlideBox").style.overflowY  = "auto"

            }

            if(currentStep <= 17){

                document.querySelector(".tableActionBtns").style.position = "static"

            }

            if(currentStep == 19){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand()

                }

            }

            if(currentStep == 20){

                if(!this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand()

                }

            }

        },
        handleTourStop(){

            document.querySelector(".tableActionBtns").style.position = "sticky"

            document.querySelector(".productSlideBox").style.overflowY  = "auto"

            this.resetPreview();

            this.tourIsActive = false;

        },
        handleTourFinish(){

            document.querySelector(".tableActionBtns").style.position = "sticky"

            document.querySelector(".productSlideBox").style.overflowY  = "auto"

            this.resetPreview();

            this.tourIsActive = false;

        },
        handleTourSkip(){

            document.querySelector(".tableActionBtns").style.position = "sticky"

            document.querySelector(".productSlideBox").style.overflowY  = "auto"

            this.resetPreview();

            this.tourIsActive = false;
            
        },
        //tour methods end

    },
    mounted(){

        this.$refs.expensesTable.addEventListener('scroll', this.handleScrollEvent);

        window.addEventListener('keydown',this.keyEvent);

        window.addEventListener('beforeunload', (event) => {

            let index = null;

            this.expensesList.forEach((expense) => {

                if(expense.id == -1){

                    index = this.expensesList.indexOf(expense);

                }

            });

            if(index != null){

                event.returnValue = "Are you sure you want to leave? entered information will be lost";

            }

        });

        //master search
        if(this.$route.query.search){
      
            this.searchedItem = this.$route.query.search

            if(this.$route.query.preview == 1){

                this.isMasterSearched = true

            }

            this.$router.replace({'query': null});

            this.searchExpense();

        }

    },
    async beforeMount(){

        this.expensesLoading = true;
        try{

            if((!this.storeExpenses || this.storeExpenses.length == 0) && this.expensesSearchKeyword.length == 0){

                this.$store.dispatch('expenses_module/fetchStoreExpensesRequest',{size: 12, page: 1, loadData: 'new'});

            }else{

                this.expensesList = _.cloneDeep(this.storeExpenses);
                this.expensesLoading = false;

            }

            if(this.expensesSearchKeyword){

                this.searchedItem = this.expensesSearchKeyword;

            }

            let custRes = await this.$axios.get("/getAllCustomers");
            if(custRes.data.status_code == "1017"){
                
                this.customersList = custRes.data.customers;

            }

            let vendorRes = await this.$axios.get("/getVendors");
            if(vendorRes.data.status_code == "1046"){

                this.vendorsList = vendorRes.data.vendors;
                
            }

            let accRes = await this.$axios.get("/account/all");
            if(accRes.data.status_code == "1052"){

                this.accountsList = accRes.data.account;
                
            }

        }catch(error){

            this.expensesLoading = false;

            this.$message({
                type: "error",
                showClose: true,
                message: error.response ? error.response.data.message : error.message,
            });

        }

    },
    beforeDestroy(){

        window.removeEventListener('keydown',this.keyEvent);

    },
    beforeRouteLeave(to, from, next){

        let index = null;

        this.expensesList.forEach((account) => {

            if(account.id == -1){

                index = this.expensesList.indexOf(account);

            }

        });

        if(window.innerWidth <= 1024){

            document.body.classList.remove('activeIpad');
            document.body.classList.add('hideSideBar');

        }

        if((this.previewForm.id || index != null) && to.fullPath != '/login'){

            MessageBox.confirm(
                'You may have unsaved changes, Proceeding will discard them',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Process',
                    cancelButtonText: 'Stay',
                }
            ).then(()=>{

                this.resetPreview();

                next();

            }).catch(()=>{})

        }else{

            next();

        }

    },
}
window.myFunction = function (event) {
    // reset all menu items
    document.querySelectorAll(".sectionTabsLink ul li a.active").forEach(function (item) {
        item.classList.remove("active");
    });
    // mark as active selected menu item
    event.target.classList.add("active");
};
</script>

<style>
    .category-tab-img {
        height: 400px;
        width: 400px;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        overflow: hidden;
        position: relative;
    }
    .category-tab-img:hover {
        border-color: #409EFF;
    }
    .category-tab-img img{
    height: 100%;
    width: 100%;
    }
    .category-tab-img svg{
    position: absolute;
    width: 50px;
    top: 43%;
    left: 0;
    right: 0;
    margin: 0 auto;
    fill: #d9d9d9;
    }
    .category-tab-img:hover svg{
    fill: #409EFF;
    }
    .fileUploaderNameBox.expense{
        display: flex;
        justify-content: space-between;
        align-content: flex-start;
    }
    .fileUploader{
        cursor: auto !important;
        gap:20px
    }
    .fileUploader .fileAction svg{
        cursor: pointer;
        width:18px
    }
</style>